import axios from "axios";
import vueAxios from "vue-axios";
import UserService from "./UserService";

class ApiService {
    static init(app, store) {
        app.use(vueAxios, axios);
        const User = UserService.getUser();
    
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json; charset=UTF-8";
        console.log(store);
        // if (store.getters.getU) {
        //     axios.defaults.headers.common["Authorization"] = "Bearer " + store.getters.getAccessToken;
        // }
        if(User){
             axios.defaults.headers.common["Authorization"] = "Bearer " + User.token
        }
        axios.defaults.baseURL = process.env.VUE_APP_API_URL        
    }

    static setToken(token) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    static get(url, payload) {
        return new Promise(function (resolve, reject) {
            axios.get(url, { params: payload }).then(({ data }) => {
                resolve(data);
            }).catch((err) => {
                reject(err);
            });
        })
    }

    static post(url, payload) {

        return new Promise(function (resolve, reject) {
            axios.post(url, payload).then(({ data }) => {
                resolve(data);
            }).catch((err) => {
                reject(err.response.data);
            });

        })
    }




    static del(url, payload) {
        return new Promise(function (resolve, reject) {
            axios.delete(url, { data: payload }).then(({ data }) => {
                resolve(data);
            }).catch((err) => {
                reject(err);
            });
        })
    }





    static put(url, payload) {

        return new Promise(function (resolve, reject) {

            axios.put(url, payload).then(({ data }) => {
                resolve(data);
            }).catch((err) => {
                reject(err.response.data);
            });

        })
    }
}

export default ApiService;