import { createStore } from "vuex";

function swapElements(arr, i1, i2) {
  [arr[i1], arr[i2]] = [arr[i2], arr[i1]];
  arr[i1].rank = i1;
  arr[i2].rank = i2;
}

export default createStore({
  state: {
    exam: { name: "", questions: [] },
    currentHeroImage: "space.jpg",
    currentToolMenu: "text",
    currentResizingImage: null,
    copiedTextStyle: null,
    singleItemDraggable: false,
    colorBgDropDown: null,
    bgPictureChanging: false,
    updatingId: false,
    previousCopiedTextStyles: [],
    allowCopyElement: true,
    copiedElement: null,
    history: [],
    canvas: {
      title: "",
      "canvas-width": 1440,
      "canvas-height": 768,
      name: "Untitled",
      components: [],
    },
    choosenSingleItem: null,
    focusedSmallExam: null,
    choosenScenario: null,
    isAnyItemDragging: false,
    choosenSingleItemInfo: null,
    inPreviewMode: false,
    choosenEntry: null,
    currentUpload: null,
    minToRead: 0,
    canLeave: false,
    sideBarStatus: true,
    rowElementActive: "",
    quizComponentFinisStatus: false,
    optionChooseStatus: false,
    contentVisibility: "PRIVATE",
  },

  getters: {
    getContentVisibility: (state) => {
      return state.contentVisibility;
    },
    getMinToRead: (state) => {
      return state.minToRead;
    },
    getOptionChooseStatus: (state) => {
      return state.optionChooseStatus;
    },

    getquizComponentStatus: (state) => {
      return state.quizComponentFinisStatus;
    },

    getrowElementActive: (state) => {
      return state.rowElementActive;
    },

    getSideBarStatus: (state) => {
      return state.sideBarStatus;
    },

    getCanLeave: (state) => {
      return state.canLeave;
    },
    getHistory: (state) => {
      return state.history;
    },
    getIsSingleItemDraggable: (state) => {
      return state.singleItemDraggable;
    },
    getPreviousTextStyles: (state) => {
      return state.previousCopiedTextStyles;
    },
    getCopiedTextStyle: (state) => {
      return state.copiedTextStyle;
    },
    getShouldCopyElement: (state) => {
      return state.allowCopyElement;
    },
    getCopiedElement: (state) => {
      return state.copiedElement;
    },
    getUpdatingId: (state) => {
      return state.updatingId;
    },
    getCurrentUpload: (state) => {
      return state.currentUpload;
    },
    getChoosenEntry: (state) => {
      return state.choosenEntry;
    },
    getInPreviewMode: (state) => {
      return state.inPreviewMode;
    },
    getChoosenSingleItemInfo: (state) => {
      return state.choosenSingleItemInfo;
    },
    getIsAnyItemDragging: (state) => {
      return state.isAnyItemDragging;
    },
    getChoosenScenario: (state) => {
      return state.choosenScenario;
    },
    getFocusedExam: (state) => {
      return state.focusedSmallExam;
    },
    getChoosenSingleItem: (state) => {
      return state.choosenSingleItem;
    },
    getExam: (state) => {
      return state.exam;
    },
    getCanvas: (state) => {
      return state.canvas;
    },
    getBgPictureChanging: (state) => {
      return state.bgPictureChanging;
    },
    getColorBgDropDrown: (state) => {
      return state.colorBgDropDown;
    },
    getCurrentHeroImage: (state) => {
      return state.currentHeroImage;
    },
    getCurrentToolMenu: (state) => {
      return state.currentToolMenu;
    },
    getCurrentResizingImage: (state) => {
      return state.currentResizingImage;
    },
  },
  mutations: {
    setContentVisibility: (state, payload) => {
      state.contentVisibility = payload;
    },

    addminToRead: (state, payload) => {
      state.minToRead += payload;
    },
    setOptionChooseStatus: (state, payload) => {
      state.optionChooseStatus = payload;
    },

    setquizComponentStatus: (state, payload) => {
      state.quizComponentFinisStatus = payload;
    },
    setrowElementActive: (state, payload) => {
      state.rowElementActive = payload;
    },

    setSideBarStatus: (state, payload) => {
      state.sideBarStatus = payload;
    },

    setCanLeave: (state, payload) => {
      state.canLeave = payload;
    },
    setHistory: (state) => {
      state.history.unshift(JSON.parse(JSON.stringify(state.canvas)));
    },
    setIsItemDraggable: (state, payload) => {
      state.singleItemDraggable = payload;
    },
    setShouldCopy: (state, payload) => {
      state.allowCopyElement = payload;
    },
    setCopiedTextStyle: (state, payload) => {
      state.previousCopiedTextStyles.push(payload);
      state.copiedTextStyle = payload;
    },

    setCopiedElement: (state, payload) => {
      state.copiedElement = payload;
    },
    setUpdatingId: (state, payload) => {
      state.updatingId = payload;
    },
    setUploadProgress: (state, payload) => {
      state.currentUpload.progress = payload;
    },
    setCurrentUpload: (state, payload) => {
      state.currentUpload = payload;
    },
    setChoosenEntry: (state, payload) => {
      state.choosenScenario = null;
      state.choosenEntry = payload;
    },
    reOrderCanvas: (state) => {
      state.canvas = state.canvas.components.sort((a, b) => {
        if (a.rank < b.rank) {
          return -1;
        } else {
          return 1;
        }
      });
    },
    setInPreviewMode: (state, payload) => {
      state.inPreviewMode = payload;
    },
    setChoosenSingleItemInfo: (state, payload) => {
      state.choosenSingleItemInfo = payload;
    },
    setIsAnyItemDragging: (state, payload) => {
      state.isAnyItemDragging = payload;
    },
    setChoosenScenario: (state, payload) => {
      state.choosenEntry = null;
      state.choosenScenario = payload;
    },
    swapItems: (state, payload) => {
      if (payload.newIndex < 0) {
        payload.newIndex = 0;
      }
      if (payload.newIndex >= state.canvas.components.length) {
        payload.newIndex = state.canvas.components.length - 1;
      }
      swapElements(
        state.canvas.components,
        payload.newIndex,
        payload.currentIndex
      );
    },

    removeItem: (state, payload) => {
      state.canvas.components.splice(payload.index, 1);
    },
    setCanvas: (state, payload) => {
      state.canvas = payload;
    },
    addToCanvasWithIndex: (state, payload) => {
      state.canvas.components.splice(
        payload.index,
        0,
        JSON.parse(payload.data)
      );
      state.minToRead += JSON.parse(payload.data).minToRead;

      state.canvas.components.forEach((comp, index) => {
        comp.rank = index;
      });
      // console.log(state.canvas.components);
      state.history.unshift(JSON.parse(JSON.stringify(state.canvas)));
    },
    addToCanvasToEnd: (state, payload) => {
      let rank = state.canvas.components.length;

      state.minToRead += JSON.parse(payload.data).minToRead;
      let data = { rank: rank, ...JSON.parse(payload.data) };
      state.canvas.components.push(data);
      state.choosenSingleItem = data;
      state.history.unshift(JSON.parse(JSON.stringify(state.canvas)));
    },
    setFocusedExam: (state, payload) => {
      state.focusedSmallExam = payload;
    },
    setChoosenSingleItem: (state, payload) => {
      state.choosenSingleItem = payload;
    },
    setExam: (state, payload) => {
      state.exam = payload;
    },
    setBgPictureChanging: (state, payload) => {
      state.bgPictureChanging = payload;
    },
    setColorBgDropDrown: (state, payload) => {
      state.colorBgDropDown = payload;
    },
    setCurrentHeroImage: (state, payload) => {
      state.currentHeroImage = payload;
    },
    setCurrentToolMenu: (state, payload) => {
      state.currentToolMenu = payload;
    },
    setCurrentResizingImage: (state, payload) => {
      state.currentResizingImage = payload;
    },
    removeElement: (state, payload) => {
      //elementIndex: props.elementIndex, innerIndex: props.innerIndex

      if (payload.innerIndex == null) {
        state.canvas.components.splice(payload.elementIndex, 1);
      } else {
        state.canvas.components[
          payload.elementIndex
        ].properties.pc.columns.splice(payload.innerIndex, 1);
      }
    },
  },
  actions: {},
});
