

export const getUser = () => {

    const a = window.localStorage.getItem("user");
    if (a != null) {
        return JSON.parse(a);
    }
    else {
        return null;
    }
};

export const saveUser = (user) => {

    window.localStorage.setItem("user", JSON.stringify(user));
};


export const destroyUser = () => {
    window.localStorage.removeItem("user");
};

export default { getUser, saveUser, destroyUser };
