<template>
  <div class="flex justify-center">
    <el-upload
      v-if="user"
      :on-change="onFileUploadError"
      name="video"
      :on-error="onFileUploadError"
      :on-progress="onFileUploading"
      :on-success="onSuccessfullyUploaded"
      class="upload-demo hidden"
      :headers="requestHeaders"
      action="https://node-admin.incore.ai/upload/video"
    >
      <button class="videoUploadMain"></button>
    </el-upload>
    <Transition
      enter-active-class="animate__animated animate__bounceInDown"
      leave-active-class="animate__animated animate__bounceOutRight"
    >
      <div
        v-if="getCurrentUpload && uploadingNow"
        class="absolute flex top-2 right-2 bg-green-400 h-12 w-72 rounded z-[1000]"
      >
        <div class="flex gap-2 w-full items-center justify-center">
          <el-icon>
            <InfoFilled class="text-white" />
          </el-icon>
          <span class="font-poppins text-sm font-medium text-center text-white">
            Uploading Video. Please Wait...</span
          >
        </div>
        <div
          class="absolute bottom-0 left-0 bg-green-200 h-2"
          :style="{ width: getCurrentUpload.progress + '%' }"
        ></div>
      </div>
    </Transition>
    <router-view />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import store from "@/store";
import { InfoFilled } from "@element-plus/icons-vue";
import UserService from "@/services/UserService";

export default {
  components: {
    InfoFilled,
  },
  setup() {
    const uploadingNow = ref(false);
    const onSuccessfullyUploaded = (e) => {
      // getChoosenSingleItem.value.src = e.data;

      getCurrentUpload.value.element.src = e.data;
      store.commit("setCurrentUpload", null);
      uploadingNow.value = false;
    };
    const user = UserService.getUser();
    const requestHeaders = ref({
      Authorization: user ? `Bearer ${user.token}` : "",
    });

    const onFileUploadError = (e) => {
      console.log("ERROR:", e);
    };

    const getCurrentUpload = computed(() => {
      return store.getters.getCurrentUpload;
    });

    const onFileUploading = (e) => {
      uploadingNow.value = true;
      store.commit("setUploadProgress", e.percent);
    };
    return {
      onSuccessfullyUploaded,
      getCurrentUpload,
      onFileUploading,
      uploadingNow,
      onFileUploadError,
      requestHeaders,
    };
  },
};
</script>
<style>
/*
@import "https://pagecdn.io/lib/easyfonts/fonts.css";
*/
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";

@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Alfa+Slab+One&family=Bebas+Neue&family=Caveat:wght@400;700&family=Comfortaa:wght@400;700&family=Dancing+Script:wght@400;700&family=IBM+Plex+Mono:wght@400;700&family=Inconsolata:wght@400;700&family=Indie+Flower&family=Lato:wght@400;700&family=Lobster&family=Lora:wght@400;700&family=Merriweather:wght@400;700&family=Montserrat:wght@400;700&family=Nunito:wght@400;700&family=Open+Sans:wght@400;700&family=PT+Serif:wght@400;700&family=Pacifico&family=Playfair+Display:wght@400;700&family=Poppins:wght@400;700&family=Roboto+Mono:wght@400;700&family=Roboto+Slab:wght@400;700&family=Shadows+Into+Light&family=Source+Code+Pro:wght@400;700&family=Space+Mono:wght@400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap");

@font-face {
  font-family: Gilroy-ExtraBold;
  src: url("@/assets/fonts/Gilroy-ExtraBold.otf");
}

@font-face {
  font-family: Gilroy-Light;
  src: url("@/assets/fonts/Gilroy-Light.otf");
}

* {
  --el-color-primary: #4d00b3;
}

#app {
  background: white;
}

a {
  list-style: none;
}

button {
  outline: unset;
  border: unset;
  cursor: pointer;
}
.navbarWrapper {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.el-input__inner {
  font-family: "poppins";
}

.el-input__inner::placeholder {
  font-family: "poppins";
}

.video-js .vjs-big-play-button {
  inset: 0;
  margin: auto;
}

#vjt-tooltip {
  font-family: "poppins";
  background-color: #4d00b3 !important;
}

#vjt-arrow {
  background-color: #4d00b3 !important;
}

.vjt-actions button:hover {
  background-color: #fff !important;
  color: #4d00b3 !important;
}

.addElementClick {
  color: #4d00b3;
  widows: 40px;
  height: 40px;
}

.textElementContantable {
  outline: none;
}

.el-upload-dragger .el-upload__text {
  font-family: "poppins";
}

.el-upload-dragger .el-upload__text em {
  color: #4d00b3 !important;
  font-style: normal;
}

.rotatingSvg90 {
  transform: rotate(-90deg);
}

.rotatinSvg135 {
  transform: rotate(135deg);
}

.toolBarItem {
  background-color: #eff5fc;
  color: #8f929b;
  transition: 150ms ease-in-out;
}

.toolBarItemEmptyElement {
  background-color: #fff;
  color: #8f929b;
  transition: 150ms ease-in-out;
}

.toolBarItemEmptyElement:hover {
  background-color: #4d00b3;
  color: #fff;
}

.toolBarItemEmptyElement .elementIcon {
  transition: 600ms ease-in-out;
}

.toolBarItemEmptyElement:hover .elementIcon {
  transform: rotateY(180deg);
}

.toolBarItem:hover {
  background-color: #4d00b3;
  color: #ebebeb;
}

.settingMenuItem {
  background-color: #fff;
  color: #96a0b5;
  transition: 200ms;
}

.settingMenuItem:hover {
  background-color: #4d00b3;
  color: #ebebeb;
}

.settingMenuItem:hover .el-color-picker__color .is-alpha {
  background-color: #ebebeb;
}

.settingMenuItem:hover .el-color-picker__color {
  border-color: #ebebeb;
}

.settingMenuItem:hover .el-color-picker,
.settingMenuItem:hover .el-color-picker__panel {
  --el-color-picker-alpha-bg-a: #ebebeb !important;
}

.alignItemsLogo {
  transition: 200ms ease-in-out;
  border-radius: 6px;
  padding: 5px;
}

.alignItemsLogo:hover {
  background-color: #4d00b3;
  color: #fff;
}

.rowButtonsGroup .rowButton {
  background-color: #fff;
  color: #96a0b5;
  transition: 200ms ease-in-out;
  width: 27px;
  padding: 0.15rem;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rowButtonsGroup .rowButton:hover {
  background-color: #4d00b3;
  color: #fff;
}

.rowButtonsGroup .rowButton.delete:hover {
  color: red;
}

.defaultBorder .el-input__wrapper {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 25%);
}

.flashcardInnerButton {
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  bottom: 0px;
  right: 0px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 25%);
  color: #96a0b5;
  background-color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 150ms ease-in-out;
}

.flashcardInnerButton.delete {
  right: 35px;
}

.flashcardInnerButton:hover {
  background-color: #4d00b3;
  color: #fff;
}

.scenarioButton {
  font-size: 11px;
  cursor: pointer;
  bottom: 0px;
  right: 0px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 25%);
  color: #96a0b5;
  background-color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 150ms ease-in-out;
}
.scenarioButton:hover {
  background-color: #4d00b3;
  color: #fff;
}

.addNewContentButton {
  cursor: pointer;
  position: absolute;
  bottom: -12px;
  right: 0;
  left: 0;
  opacity: 70%;
  transition: 150ms;
  background-color: #4d00b3;
  color: #fff;
  width: 25px;
  margin: auto;
  border-radius: 50%;
  height: 25px;
}
.addNewContentButton:hover {
  opacity: 100%;
}

.textElementCustom {
  background-color: #eff5fc;
  margin-bottom: 0.4rem;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 25%);
  border-radius: 4px;
  padding: 5px 10px;
}
.customDrawer {
  z-index: 999999999 !important;
}
.customDrawer .el-drawer {
  z-index: 999999999 !important;
}

@media screen and (max-width: 1024px) {
  .sidebar {
    left: -100% !important;
  }
  .sidebar.active {
    left: 0% !important;
  }
  .mainContent {
    position: relative !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 3rem !important;
  }
  .openSideBar,
  .closeSideBar {
    display: block !important;
  }
}

.el-select-dropdown__empty {
  font-family: "Poppins", sans-serif;
}

.responsiveMenuLogo {
  display: none;
}
@media screen and (max-width: 998px) {
  .responsiveMenuLogo {
    display: block;
  }
}

.customDrawer .el-drawer {
  width: 30% !important;
}

@media screen and (max-width: 1024px) {
  .customDrawer .el-drawer {
    width: 50% !important;
  }
}

@media screen and (max-width: 768px) {
  .customDrawer .el-drawer {
    width: 70% !important;
  }
}

@media screen and (max-width: 425px) {
  .customDrawer .el-drawer {
    width: 100% !important;
  }
}

.typed-cursor {
  background: -webkit-linear-gradient(
    45deg,
    var(--primaryColor),
    var(--btnSecondaryColor)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#app {
  background: var(--bodyColor);
}

@media screen and (max-width: 998px) {
  .navbar {
    flex-direction: column;
    position: fixed;
    inset: 0;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 1) 35%,
      rgba(148, 148, 148, 1) 100%
    );
    height: 100vh;
    z-index: 100000;
    display: none;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    gap: 10px;
  }

  .navbar.active {
    display: flex;
    animation: fadeINMenu 200ms forwards;
  }

  .navbar .navbarMenu,
  .navbar .navbarActions {
    flex-direction: column;
    gap: 10px;
  }

  .navMobil {
    display: flex;
  }

  .closeMenuButton {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
@media screen and (min-width: 1900px) {
  .widgetWrapper {
    max-width: 1580px;
    margin: auto;
  }
}
@media screen and (max-width: 1900px) {
  .widgetWrapper {
    max-width: unset;
  }
}
@media screen and (min-width: 998px) {
  .navbar {
    display: flex;
  }

  .closeMenuButton {
    display: none;
  }

  .navMobil {
    display: none;
  }
}
@keyframes fadeINMenu {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes rotateback {
  0% {
    -webkit-transform: perspective(500px) rotateX(1deg) rotateY(90deg);
    transform: perspective(500px) rotateX(1deg) rotateY(90deg);
  }

  100% {
    -webkit-transform: perspective(500px) rotateX(1deg) rotateY(10deg);
    transform: perspective(500px) rotateX(1deg) rotateY(10deg);
  }
}

.previewBottomContent {
  padding: 0px 75px;
}

.areaHeader.haveBorder {
  border-bottom: 1px solid gray;
  padding-bottom: 1rem;
}

.areaCardList .contentCard .cardImage {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 50%);
}

.otherContentFlowArea .contentCard {
  width: calc(25% - 1rem);
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1024px) {
  .otherContentFlowArea .contentCard {
    width: 100%;
  }
  .previewBottomContent {
    padding: 0px 5px;
  }
}
.v-enter-active {
  transition: opacity 0.5s ease;
}

.v-leave-active {
  transition: opacity 0 ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.default-incore-button {
  background: linear-gradient(
    90deg,
    rgba(102, 16, 245, 1) 0%,
    rgba(87, 87, 246, 1) 60%,
    rgba(72, 160, 248, 1) 100%
  );
  border-radius: 30px;
  cursor: pointer;
  padding: 9px 30px 9px 25px;
}

.default-incore-button:hover {
  background: linear-gradient(
    90deg,
    rgba(102, 16, 245, 1) 0%,
    rgba(87, 87, 246, 1) 60%,
    rgba(72, 160, 248, 1) 100%
  );
}

.text-incore-button {
  background: transparent;
  cursor: pointer;
  padding: 9px 30px 9px 25px;
}

@media only screen and (max-width: 768px) {
  .rowWrapperElement {
    flex-direction: column !important;
  }
  .rowWrapperElement section {
    width: 100% !important;
  }
  .ql-editor {
    text-align: center !important;
  }
}

.ql-tooltip.ql-flip {
  left: 0 !important;
}
</style>
