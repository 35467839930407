import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.css";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import LazyLoadDirective from "./directives/LazyLoadDirective";
import "@/assets/css/index.scss";
import mitt from "mitt";

import ApiService from "./services/ApiService";

import CKEditor from "@ckeditor/ckeditor5-vue";

import VNetworkGraph from "v-network-graph";
import "v-network-graph/lib/style.css";

import VueVideoPlayer from "@videojs-player/vue";
import "video.js/dist/video-js.css";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import VueJsTour from "@globalhive/vuejs-tour";
import "@globalhive/vuejs-tour/dist/style.css";
import Vue3Lottie from "vue3-lottie";
import "vue3-lottie/dist/style.css";
const app = createApp(App);
import VueApexCharts from "vue3-apexcharts";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.bubble.css";
import VueEasyLightbox from 'vue-easy-lightbox'
import VueSocialSharing from 'vue-social-sharing'
const emitter = mitt();
app.use(VueJsTour);

app.component(QuillEditor);
ApiService.init(app, store);

app.directive("lazyload", LazyLoadDirective);
app.use(VueSocialSharing);
app.use(VueApexCharts);
app.use(VueVideoPlayer);
app.use(store);
app.use(CKEditor);
app.use(VNetworkGraph);
app.use(VueEasyLightbox)
app.use(Vue3Lottie);
app.provide("emitter", emitter);
app.mixin({
  methods: {
    uuidv4: function () {
      let uuid = ([1e7] + 1e3 + 4e3 + 8e3 + 1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );

      return "i" + uuid;
    },
  },
});
app.use(VueSweetalert2);
app.use(router);
app.use(ElementPlus);
app.mount("#app");
