import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "landing",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/Landing/LandingPage.vue"
      );
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/Dashboard/DashboardScreen.vue"
      );
    },
  },
  {
    path: "/community",
    name: "community",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/pages/CommunityScreen.vue"
      );
    },
  },
  {
    path: "/category/:slug",
    name: "CategoryDetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/pages/CategoryDetail.vue"
      );
    },
  },
  {
    path: "/choose-incore",
    name: "chooseincore",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/pages/ChooseIncoreWidget.vue"
      );
    },
  },
  {
    path: "/what-you-can-create",
    name: "whatyoucancreate",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/pages/WhatYouCanCreate.vue"
      );
    },
  },
  {
    path: "/privacy-policy",
    name: "privacypolicy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/pages/PrivacyPolicy.vue"
      );
    },
  },
  {
    path: "/kvkk",
    name: "kvkk",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/pages/KvkkScreen.vue"
      );
    },
  },

  {
    path: "/cookie",
    name: "Cookie",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/pages/CookiePreferences.vue"
      );
    },
  },
  {
    path: "/contact-us",
    name: "contactus",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/pages/ContactUs.vue"
      );
    },
  },
  {
    path: "/incore-education",
    name: "incoreeducation",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/pages/IncoreEducation.vue"
      );
    },
  },
  {
    path: "/my-incores",
    name: "myincores",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/MyIncores/MyIncores.vue"
      );
    },
  },
  {
    path: "/settings",
    name: "settings",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/Dashboard/SettingsScreen.vue"
      );
    },
  },
  {
    path: "/content-flow",
    name: "contentflow",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/Editor/ContentFlow/ContentFlow.vue"
      );
    },
  },

  {
    path: "/preview/:id",
    name: "Preview",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/Editor/ContentFlow/PreviewScreen.vue"
      );
    },
  },
  {
    path: "/embedScreen/:id",
    name: "EmbedScreen",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/Editor/ContentFlow/embedScreen.vue"
      );
    },
  },
  {
    path: "/i/:id",
    name: "SlugPreview",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/Editor/ContentFlow/PreviewScreen/ContentView.vue"
      );
    },
  },

  {
    path: "/quick-exam-detail/:id",
    name: "QuickExamDetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/pages/QuickExamDetail.vue"
      );
    },
  },

  {
    path: "/previewQuickExam",
    name: "PreviewQuickExam",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/Export/PreviewQuickExam.vue"
      );
    },
  },
  {
    path: "/solve-quick-exam/:id",
    name: "SolveQuickExam",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/Export/SolveQuickExam.vue"
      );
    },
  },
  {
    path: "/quick-exam",
    name: "quickexam",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/Editor/QuickExam/QuickExam.vue"
      );
    },
  },
  {
    path: "/video-gaming",
    name: "VideoGaming",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import("@/views/Editor/VideoGaming/VideoGaming.vue");
    },
  },
  {
    path: "/user-profile/:id",
    name: "UserPofile",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: function () {
      /* eslint-disable */
      return import("@/views/pages/UserProfile/UserProfile.vue");
    },
  },
  {
    path: "/sign-in",
    name: "signin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import("@/views/Auth/SignIn.vue");
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/pages/ForgotPassword.vue"
      );
    },
  },
  {
    path: "/test",
    name: "test",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/pages/TestScreen.vue"
      );
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
